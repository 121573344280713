
import './App.css';
import Main from './components/main';


function App() {
  return (
    <div>
      <h1>
        <Main/>
      </h1>
    </div>
  );
}

export default App;
