import React from 'react'
import videoBg from '../assets/bgvid.mp4'
import logo from '../assets/logo.png'
import Contact from './contact';


const Main = () => {
    return (
        <div className='main'>
            <div className='overlay'></div>
            <video src= {videoBg} autoPlay muted loop/>
            <div className='content'>
                <div className='text'>
                    <img className='logo' src={logo} alt="" />
                    <h1>AlgemyLabs</h1>
                    <h2>transforming food production from the ground up with biotechnology</h2>
                    <Contact/>
                </div>
            </div>
        </div>
    )
}

export default Main